const defaultConfig = {
  env: process.env.NODE_ENV || 'development',
  localApiEndpoint: 'http://localhost:3635',
  maxRetryCount: process.env.MAX_RETRY_COUNT || 4
};

const developmentConfig = {
  qsApiEndPoint: 'https://api.dev.quicksell.co'
};

const productionConfig = {
  qsApiEndPoint: 'https://api.quicksell.co'
};

let config;
if (defaultConfig.env === 'production') {
  config = { ...defaultConfig, ...productionConfig };
} else {
  config = { ...defaultConfig, ...developmentConfig };
}

export default config;
