import Api from './Api';
const { ipcRenderer } = window.require('electron');

export const getUserTallyConfig = () => {
  Api.fetchTallySettings()
    .then(response => {
      const { value = {} } = response;
      ipcRenderer.send('FETCH_TALLY_SETUP_DATA', { tallySettingsData: value });
    })
    .catch(() => {
      ipcRenderer.send('FETCH_TALLY_SETUP_DATA', { tallySettingsData: {} });
    });
};
