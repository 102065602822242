import React, { useEffect, useRef, useState, useMemo } from 'react';

import {
  getCompanyMetaFromLocalStorage,
  onLogout
} from '../../Services/DataManager/Authentication';

import Logo from '../../Assets/Media/logo.png';
import OrderListener from '../../Services/Order';
import Loader from '../Common/Loader';
import './index.scss';
import { generateExcel } from '../../Services/GenerateExcel';

const Dashboard = ({ setAuthenticated }) => {
  const [userConnector] = useState(window.userConnector || false);
  const [syncingOrders, setSyncingOrders] = useState(false);
  const [numberOfOrdersToSync, setNumberOfOrdersToSync] = useState(0);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const [downloadedExcelMessage, setDownloadedExcelMessage] = useState('');
  const ref = useRef(null);

  const company = getCompanyMetaFromLocalStorage();

  const failedOrderCallback = orders => {
    setNumberOfOrdersToSync(orders.length);
  };

  const orderListener = useMemo(
    () => new OrderListener({ companyId: company.id, failedOrderCallback }),
    [company.id]
  );

  const getCompanyLogo = () => {
    if (!company) {
      return '';
    }

    const { logoExists, logoVersion, name = '' } = company;
    if (!logoExists) {
      return name.trim()[0] || '';
    }
    return (
      <img
        src={`https://s3.ap-south-1.amazonaws.com/quicksell-logos/${company.id}.jpg?v=${logoVersion}`}
        alt="Your company profile logo"
      />
    );
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowUserDetails(false);
    }
  };

  const logoutUser = event => {
    event.preventDefault();
    onLogout();
    setAuthenticated(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (!company.id) {
      onLogout();
      setAuthenticated(false);
      return;
    }
    !userConnector && orderListener.attachListeners();
    return () => !userConnector && orderListener.removeListeners();
  }, [company.id, orderListener, setAuthenticated, userConnector]);

  const toggleShowUserDetails = event => {
    event.preventDefault();
    setShowUserDetails(!showUserDetails);
  };

  const syncFailedOrders = async event => {
    event.preventDefault();
    setSyncingOrders(true);
    try {
      await orderListener.processFailedOrders();
      setSyncingOrders(false);
    } catch (error) {
      setSyncingOrders(false);
    }
  };

  const downloadExcel = event => {
    event.preventDefault();
    setDownloadedExcelMessage('');
    setDownloadingExcel(true);
    generateExcel(setDownloadingExcel, setDownloadedExcelMessage);
  };

  const renderSyncData = () => {
    if (syncingOrders) {
      return (
        <div className="syncingOrders">
          <div>
            <Loader size={'small'} />
          </div>
          <p className="syncingTitle">Syncing orders to tally</p>
        </div>
      );
    }

    if (typeof numberOfOrdersToSync === 'number' && numberOfOrdersToSync > 0) {
      return (
        <div className="syncNow">
          <p className="syncNowTitle">
            {numberOfOrdersToSync} order{numberOfOrdersToSync > 1 && 's'} could not be synced
          </p>
          <button className="syncNowButton" onClick={syncFailedOrders}>
            Sync now
          </button>
        </div>
      );
    }

    return <p className="ordersSynced">All orders synced to tally successfully</p>;
  };

  const renderUserDetailsModal = () => {
    if (!showUserDetails) {
      return null;
    }
    return (
      <div ref={ref} className="userDetailsModal">
        <div className="userLogo"></div>
        <p className="companyName">{company.name || ''}</p>
        <div className="logoutButtonContainer">
          <button className="logoutButton" onClick={logoutUser}>
            Logout
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="dashboardContainer">
      <div className="userDetails">
        <div className="userIcon" onClick={toggleShowUserDetails}>
          {getCompanyLogo()}
        </div>
        {renderUserDetailsModal()}
      </div>
      <div className="quicksellInfo">
        <img src={Logo} className="quicksellLogo" alt="" />
        <p className="appTitle">Quicksell tally connector</p>
      </div>
      <div className="dashboardActionsContainer">
        <div className="titleSection">
          {!userConnector && <p className="title">Order Sync status</p>}
          <p className="title">Export products</p>
        </div>
        <div className="contentSection">
          {!userConnector && <div className="syncSection">{renderSyncData()}</div>}
          <div className="excelSection">
            {downloadingExcel ? (
              <Loader size={'small'} />
            ) : (
              <>
                <button className="excelDownloadButton" onClick={downloadExcel}>
                  Download Excel
                </button>
                {downloadedExcelMessage && (
                  <p className="downloadExcelMessage">{downloadedExcelMessage}</p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
